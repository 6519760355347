import $ from 'jquery'
import 'materialize-css'
import './vue/index.js';
import './modules/googlemapsinfobox.js'
import LoveForm from './modules/loveform'
import Navigation from './modules/navigation'
import Mmenu from './modules/mmenu'
import Materialize from './modules/materialize'
import Scroll from './modules/scroll'
import './modules/event-archive'

import ImagesLoaded from 'imagesloaded'
import Masonry from 'masonry-layout'

import {initBaquettebox, initBaquetteboxArchive} from './modules/baguettebox'
import {initSwiper} from './modules/swiper'
import {initSwiperHeader} from './modules/swiper'

new Materialize()
new Mmenu()
new Navigation()
new LoveForm()
new Scroll()

function initMasonry() {
    const masonrySelector = '.galleria'
    if (document.querySelector(masonrySelector) !== null) {
        ImagesLoaded(masonrySelector, () => {
            this.masonry = new Masonry(masonrySelector, {
                itemSelector: '.galleria-item'
            })
        })
    }
}

function derTeich() {
    // Color Box mobile Open Accordeon
    $('.color-box__inner').click(function () {
        $(this).toggleClass('open')
    })
}

$(document).ready(() => {
    initSwiper()
    initSwiperHeader()
    initBaquettebox()
    initBaquetteboxArchive()
    initMasonry()
    derTeich()
})