import $ from 'jquery';

export default class Navigation {
    constructor() {
        this.init();
        this.didScroll = false;
        this.lastScrollTop = 0;
        this.delta = 1;
        this.navbarHeight = $('header').outerHeight() + 70;
    }

    init() {
        this.initToggleMenuEvents();
        this.initStickyNavigation();
    }

    initStickyNavigation() {
        $(window).scroll(() => {
            this.didScroll = true;
        });
        setInterval(() => {
            if (this.didScroll) {
                this.hasScrolled();
                this.didScroll = false;
            }
        });
    }

    initToggleMenuEvents() {
        const menuTogglerOpen = $('.menu-toggler__open');
        const menuTogglerClose = $('.menu-toggler__close');
        const mainNavigation = $('nav.main-navigation');
        const headerImage = $('.header-image');
        const header = $('header');
        menuTogglerOpen.click(function() {
            mainNavigation.addClass('open');
            $(this).addClass('hidden');
            menuTogglerClose.addClass('shown');
            $('html').addClass('main-navigation--open');
        });
        menuTogglerClose.click(function() {
            mainNavigation.removeClass('open');
            $(this).removeClass('shown');
            menuTogglerOpen.removeClass('hidden');
            $('html').removeClass('main-navigation--open');
        });

        if ($(headerImage).length) {
            header.addClass('header-image--exists')
        }
    }


    hasScrolled() {
        let st = window.pageYOffset || document.documentElement.scrollTop;

        // Make sure they scroll more than delta
        if(Math.abs(this.lastScrollTop - st) <= this.delta) {
            return false;
        }

        // If they scrolled down and are past the navbar, add class .nav-up.
        // This is necessary so you never see what is "behind" the navbar.
        const headerElement = $('header');
        if (st > this.lastScrollTop && st > this.navbarHeight){
            // Scroll Down
            headerElement.addClass('fixed');
            headerElement.removeClass('scrollup');
        } else if (st < this.navbarHeight) {
            headerElement.removeClass('scrollup');
        } else {
            // Scroll Up
            if(st + $(window).height() < $(document).height()) {
                headerElement.removeClass('fixed');
                headerElement.addClass('scrollup');
            }
        }

        this.lastScrollTop = st;
    }

}
