<template>
    <div class="featured-events__posts">
        <div class="featured-events__filter">
            <select v-model="selectedYear" id="eventFilterYears">
                <option value="all" selected>{{ yearfilter.label }}</option>
                <option v-for="option in availableyears" :value="option.value">{{ option.label }}</option>
            </select>
            <select v-model="selectedMonth" id="eventFilterMonths">
                <option value="all" selected>{{ monthfilter.label }}</option>
                <option v-for="option in availablemonths" :value="option.value">{{ option.label }}</option>
            </select>
            <select v-model="selectedEventType" id="eventFilterEventType">
                <option value="all" selected>{{ eventtypefilter.label }}</option>
                <option v-for="option in availableeventtypes" :value="option.value">{{ option.label }}</option>
            </select>
        </div>
        <ul>
            <li v-for="event in visibleEvents" :data-date="event.start.yearMonth" :data-year="event.start.year" :data-month="event.start.month"
                :data-eventtype="event.type.id"
                :data-eventid="event.id">
                <div class="event-wrapper">
                    <div class="event__date">
                        <div class="event__date__month-year overline-1">
                            <span class="mobile-span">{{ event.start.monthStringShort }}</span>
                            <span class="desktop-span">{{ event.start.monthString }}</span>
                            {{ event.start.year }}
                        </div>
                        <div class="event__date__month-year overline-1" v-if="event.kind == 'exhibition' && event.startEndSameMonth == false">
                            <span class="event__date__divider">-</span>
                            <span class="mobile-span">{{ event.end.monthStringShort }}</span>
                            <span class="desktop-span">{{ event.end.monthString }}</span>
                            {{ event.end.year }}
                        </div>
                        <div class="event__date__number h2" v-if="event.kind == 'event'">
                            {{ event.start.day }}
                        </div>
                        <div class="event__date__day caption-1" v-if="event.kind == 'event'">
                            <span class="mobile-span">{{ event.start.dayStringShort }}</span>
                            <span class="desktop-span">{{ event.start.dayString }}</span>
                        </div>
                        <div class="event__date_hour caption-1" v-if="event.kind == 'event'">
                            {{ event.start.time }}
                        </div>
                        <div v-if="event.featuredText" class="event__date__featured subtitel-2">
                            {{ event.featuredText}}
                        </div>
                    </div>
                    <div class="event__content">
                        <div  v-if="event.headline" class="event__content__categorys overline-1">
                            {{ event.headline }}
                        </div>
                        <div class="event__content__title h4">
                            {{ event.title }}
                        </div>
                         <a :href="event.link" class="button waves-effect waves-aventurin-light">
                            {{ labels.details }}
                        </a>
                          <a :href="event.youtubelivestream" v-if="event.youtubelivestream" target="_blank" class="button button--red waves-effect waves-jasper">
                            {{ labels.toYoutubeLiveStream }}
                        </a>
                    </div>
                </div>
            </li>
        </ul>
        <div v-if="showMoreButton" @click="showMore" class="button waves-effect waves-aventurin-light button--regular">{{ labels.loadmore }}</div>
    </div>
</template>
<script>
    export default {
        props: {
            eventtypefilter: {
                type: Object,
                default: () => {}
            },
            monthfilter: {
                type: Object,
                default: () => {}
            },
            yearfilter: {
                type: Object,
                default: () => {}
            },
            events: {
                type: Array,
                default: () => []
            },
            labels: {
                type: Object,
                default: () => {}
            },
        },
        data: () => ({
                selectedMonth: 'all',
                selectedYear: 'all',
                limit: 7,
                showMoreItems: 7,
                selectedEventType: 'all'
        }),
        computed: {
            showMoreButton() {
                return this.limit < this.availableEvents.length
            },
            availablemonths() {
                if (this.selectedEventType !== 'all' || this.selectedYear !== 'all') {
                    return this.monthfilter.options.filter(
                        month => !!this.availableEvents.find(event => month.value === event.start.month)
                    );
                }

                return this.monthfilter.options;
            },
            availableyears() {
                if (this.selectedEventType !== 'all' || this.selectedMonth !== 'all') {
                    return this.yearfilter.options.filter(
                        year => !!this.availableEvents.find(event => year.value === event.start.year)
                    );
                }

                return this.yearfilter.options;
            },
            availableeventtypes() {
                if (this.selectedMonth !== 'all' || this.selectedYear !== 'all') {
                    return this.eventtypefilter.options.filter(
                        eventtype => !!this.availableEvents.find(event => eventtype.value === event.type.id)
                    );
                }

                return this.eventtypefilter.options;
            },
            availableEvents() {
                return this.events.filter(event => {
                    let show = true;
                    // filter by selected month
                    if (this.selectedMonth !== 'all') {
                        if (event.start.month === this.selectedMonth) {
                            show = true;
                        } else {
                            show = false;
                        }
                    }
                    if (this.selectedYear !== 'all') {
                        if (event.start.year !== this.selectedYear) {
                            show = false;
                        }
                    }
                    // filter by selected Event Type
                    if (this.selectedEventType !== 'all') {
                        if (event.type.id !== this.selectedEventType) {
                            show = false;
                        }
                    }
                    return show;
                });
            },
            visibleEvents() {
                return this.availableEvents.slice(0, this.limit)
            }
        },
        mounted() {
            this.initSelect();
        },
        methods: {
            showMore() {
                this.limit += this.showMoreItems
            },
            initSelect() {
                const selectElements = document.querySelectorAll('select')
                M.FormSelect.init(selectElements);

                selectElements.forEach((input1) => {
                    input1.parentNode.querySelectorAll('li[id^="select-options"]')
                        .forEach((input) => {
                            input.addEventListener('touchend', this.preventTouchesEvent)
                        });
                });
            },
            preventTouchesEvent(event) {
                event.stopPropagation();
            }
        },
        watch: {
            availablemonths: function (val) {
                this.$nextTick(() => {
                    let element = document.getElementById('eventFilterMonths');
                    M.FormSelect.init(element);
                    element.parentNode.querySelectorAll('li[id^="select-options"]')
                        .forEach((input) => {
                            input.addEventListener('touchend', this.preventTouchesEvent)
                        });
                });
            },
            availableyears: function (val) {
                this.$nextTick(() => {
                    let element = document.getElementById('eventFilterYears');
                    M.FormSelect.init(element);
                    element.parentNode.querySelectorAll('li[id^="select-options"]')
                        .forEach((input) => {
                            input.addEventListener('touchend', this.preventTouchesEvent)
                        });
                });
            },
            availableeventtypes: function (val) {
                this.$nextTick(() => {
                    let element = document.getElementById('eventFilterEventType');
                    M.FormSelect.init(element);
                    element.parentNode.querySelectorAll('li[id^="select-options"]')
                        .forEach((input) => {
                            input.addEventListener('touchend', this.preventTouchesEvent)
                        });
                });
            }
        }
    }
</script>
