import $ from 'jquery'
import Swiper from 'swiper'

export function initSwiper(selector = '.wly-slider') {
    // Only initialize swiper on elements which are actually visible and
    // have not been initialized yet.
    $(selector).each(function () {
        if ($(this).is(':visible') && !$(this).hasClass('swiper-container-initialized')) {
            new Swiper(this, {
                // Optional parameters
                direction: 'horizontal',
                loop: false,
                type: 'fraction',
                pagination: '.swiper-pagination',
                speed: 1400,
                autoplay: 5000,
                grabCursor: true,
                paginationClickable: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            })
        }
    })
}


export function initSwiperHeader(selector = '.header__slider') {
    // Only initialize swiper on elements which are actually visible and
    // have not been initialized yet.
    $(selector).each(function () {
        if ($(this).is(':visible') && !$(this).hasClass('swiper-container-initialized')) {
            new Swiper(this, {
                // Optional parameters
                direction: 'horizontal',
                loop: true,
                pagination: '.swiper-pagination',
                speed: 1400,
                autoplay: {
                    delay: 8000,
                    disableOnInteraction: true,
                },
                grabCursor: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            })
        }
    })
}
