import baguetteBox from 'baguettebox.js'

export function initBaquettebox() {
    baguetteBox.run('.gallery, .wly-slider:not(.archive-slider), .image-wrapper', { // .image-wrapper__inner .gallery, .wly-slider, .image-wrapper, .image-wrapper__inner, a[rel="noopener"]
        noScrollbars: true,
        async: true,
        titleTag: true,
        bodyClass: 'baguetteBox-open'
    })
}

export function initBaquetteboxArchive() {
    baguetteBox.run('.archive-slider', {
        noScrollbars: true,
        async: true,
        titleTag: true,
        bodyClass: 'archive-baguette-box'
    })
}
