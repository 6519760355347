import $ from 'jquery'
import {initSwiper} from './swiper'
import qs from "qs";

let $loadMoreButton = null
let $events = null
let visibleEventsCounter = 1
const addMoreEvents = 1

$(() => {
    $events = $('.eventarchive .series-events > ul > li[data-eventid]')

    $loadMoreButton = $('.archive__button_load-more').first()
    $loadMoreButton.on('click', loadMoreEvents)

    // Show the button if there are more events then currently visible
    if ($events.length > visibleEventsCounter) {
        $loadMoreButton.attr('aria-hidden', false)
    }

    showVisibleEvents()
})

function loadMoreEvents() {
    if (!$events) {
        return
    }

    visibleEventsCounter += addMoreEvents
    showVisibleEvents()

    // Hide the button if there are no more events to show
    if ($events.length <= visibleEventsCounter) {
        $loadMoreButton.attr('aria-hidden', true)
    }
}

function showVisibleEvents() {
    // Loop through all events and set there visibility state
    $events.each((index, event) => {
        const $event = $(event)
        const queryString = qs.parse(window.location.search.substring(1));
        if ((index + 1) <= visibleEventsCounter) {
            if (parseInt(queryString.eventid) === $event.data('eventid')) {
                if ((index + 1) === visibleEventsCounter) {
                    visibleEventsCounter += 1
                }
                $event.attr('aria-hidden', false)
                $('html, body').animate({
                    scrollTop: $event.offset().top - 50
                }, 1000);
            }
            $event.attr('aria-hidden', false)
        } else {
            if (parseInt(queryString.eventid) === $event.data('eventid')) {
                $event.attr('aria-hidden', false)
                $('html, body').animate({
                    scrollTop: $event.offset().top - 50
                }, 1000);
                if ((index + 1) === visibleEventsCounter) {
                    visibleEventsCounter += 1
                }
            } else {
                $event.attr('aria-hidden', true)
            }
        }
    })

    initSwiper()
}
