import $ from 'jquery';

export default class Mmenu {
    constructor() {
        // build footer navbar
        var mmlanguageswicher = '';
        /*
        TODO convert to javascript... from blade
        @if(function_exists('icl_get_languages'))
            <?php $languages = icl_get_languages('skip_missing=0&orderby=code'); ?>
        @if(!empty($languages))
            mmlanguageswicher += '<div class="language-switcher">';
            mmlanguageswicher += '<ul id="language-switcher-mm">';
        @foreach($languages as $language)
            mmlanguageswicher += '<li class="@if($language["active"]) active @endif language-switcher-{{ $language['code'] }}"><a href="{{ $language['url'] }}">';
            mmlanguageswicher += '  {{ icl_disp_language($language['translated_name']) }}';
            mmlanguageswicher += '</a></li>';
        @endforeach
            mmlanguageswicher += '</ul>',
                mmlanguageswicher += '</div>',
                @endif
        @endif
        */

        document.addEventListener(
            "DOMContentLoaded", () => {
                new Mmenu( "#mmenu", {
                    "extensions": [
                        "pagedim-black"
                    ],
                    "navbars": [{
                        "position": "top"
                    },
                        {
                            "position": "bottom",
                            "content": [mmlanguageswicher]
                        }
                    ]
                }, {
                    // configuration
                });
            }
        );



    }
}