import $ from "jquery";
import qs from "qs";


export default class Scroll {

    constructor() {
        this.scrollEventOnLinks();
        this.scrollEventOnLoad();
    }

    scrollEventOnLoad() {
        const queryString = qs.parse(window.location.search.substring(1));
        if (queryString.eventid) {
            const e = $('li[data-eventid=' + queryString.eventid + ']');
            if (e) {
                $('html, body').animate({
                    scrollTop: e.offset().top - 50
                }, 1000);

            }
        }
    }

    scrollEventOnLinks() {

        $(document).on('click', 'a[href^="#"]', function (event) {
            event.preventDefault();
            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top - 50
            }, 1000);
        });
    }
}