import $ from 'jquery';
import 'materialize-css';

export default class Materialize {
    constructor() {
        this.initMaterial();
    }

    initMaterial() {
        // weil materialize die selecct-boxen auf display none stellt, funktioniert in chrome die validierung nicht mehr
        var elems = document.querySelectorAll('select');
        var options = document.querySelectorAll('option');
        var instances = M.FormSelect.init(elems, options);

        var elems = document.querySelectorAll('.dropdown-button');
        let optionsDropdown = {
            inDuration: 50,
            outDuration: 25,
            hover: true, // Activate on hover
            belowOrigin: true, // Displays dropdown below the button
            alignment: 'right' // Displays dropdown with edge aligned to the left of button);
        };
        var instances = M.Dropdown.init(elems, optionsDropdown);

        var elems = document.querySelectorAll('.dropdown-button-sub');
        let optionsDropdown2 = {
            inDuration: 50,
            outDuration: 25,
            hover: true, // Activate on hover
            alignment: 'right' // Displays dropdown with edge aligned to the left of button);
        };
        var instances = M.Dropdown.init(elems, optionsDropdown2);

        $("select[required]").css({display: "block", height: 0, padding: 0, width: 0, position: 'absolute'});
        $("[type=checkbox]").css({left: "inherit", "margin-top": "8px"});
        $("[type=radio]").css({left: "inherit", "margin-top": "8px"});

    }
}